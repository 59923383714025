.App {
  color: white;
  background: black;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 10px;
}

.consoleInput {
  /* position: absolute;
  bottom: 0;
  width: 90%; */
}

.consoleInput > input, .consoleInput {
  padding: 0px;
  margin: 0px;
}

.consoleInput > input, .commandText > .commandValue{
  width: 40%;
  height: 20px;
  background: none;
  color: white;
  border: none;
  display: inline;
  font-size: 100%;
  padding-left: 10px;
}

.consoleInput > input:focus {
  outline: none;
}

.consolePrefix {
  color: #00ff00;
}

.consoleText {
  margin: 0px 0px;
  line-height: 1.2rem;
}

.command, .command-pad-right, a {
  cursor: pointer;
  color: cyan;
}

.command:hover, .command-pad-right:hover, a {
  text-decoration: underline;
}

.command-pad-right {
  margin-right: 10px;
}